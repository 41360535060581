import axios, { CancelToken, isCancel } from 'axios'
import { notification } from 'ant-design-vue'
import store from '@/store'
import { debounce } from '@/utils/tools'

class HttpRequest {
  constructor (baseURL) {
    this.baseURL = baseURL
    this.notice = true
    this.store = store
  }
  toggleLoading () {
    // console.log(loading)
    // this.store.dispatch({
    //   type: 'toggleLoading',
    //   payload: loading
    // })
  }
  message = debounce(({ type, message, description }) => {
    if (this.notice) {
      notification[type]({
        message,
        description,
        duration: 1
      })
    }
  }, 500)
  getInsideConfig () {
    const config = {
      baseURL: this.baseUrl,
      timeout: 1000000,
      headers: {
      }
    }
    return config
  }
  interceptors (instance) {
    const that = this
    const checkCode = code => {
      return new Promise(function (resolve, reject) {
        if (code >= 200 && code < 400) {
          return resolve()
        }
        if (code === 401) {
          this.message({
            type: 'warning',
            message: '操作失败',
            description: '没有相关权限'
          })
          return reject(new Error('没有权限'))
        }
        return reject(new Error('网络错误'))
      })
    }

    instance.interceptors.request.use(function (config) {
      if (!config.hideLoading) {
        that.toggleLoading(true)
      }
      return config
    }, error => {
      that.toggleLoading(false)
      return Promise.reject(error)
    })

    instance.interceptors.response.use(async function (response) {
      try {
        
        await checkCode(response.data.code)
        that.toggleLoading(false)
        return response.data
      } catch (e) {
        that.toggleLoading(false)
        console.log(e.message)
      }
    }, error => {
      that.toggleLoading(false)
      if (isCancel(error)) {
        return Promise.reject('request canceld')
      }
      this.message({
        type: 'error',
        message: '网络异常',
        description: '请稍后重试'
      })
      return Promise.reject(error)
    })
  }
  request (options) {
    let cancel = null
    options.cancelToken = new CancelToken(c => cancel = c)
    if (options.callback) options.callback(cancel)
    if (options.notice) this.notice = options.notice
    const instance = axios.create()
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance)
    return instance(options)
  }
}

export default new HttpRequest()


