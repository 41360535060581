import axios from "@/utils/request";
import axiosNew from "@/utils/request2";

const prefix = "/api/wiki";
const managerApi =
  "https://test-itown-wiki-manage.app-chengdu1.yunzhicloud.com";
//

export default {
  /**
   * 首页
   */
  // 获取首页banner
  getHomeBanner(params) {
    return axios.request({
      url: `${prefix}/web/video`,
      params,
      method: "GET",
    });
  },
  // 获取小镇动态
  getNewsList(params) {
    return axios.request({
      url: `${prefix}/web/article/page/trend`,
      params,
      method: "GET",
    });
  },
  // 获取精选小镇
  getTownList(params) {
    return axios.request({
      url: `${prefix}/web/town/page`,
      params,
      method: "GET",
    });
  },
  // 获取小镇封面
  getTownCover(params) {
    return axios.request({
      url: `${prefix}/web/town/pageForAlbum`,
      params,
      method: "GET",
    });
  },
  // 获取小镇相册
  getAlbumList(params) {
    return axios.request({
      url: `${prefix}/web/article/page/album`,
      params,
      method: "GET",
    });
  },
  // 获取小镇访谈列表
  getInterviewList(params) {
    return axios.request({
      url: `${prefix}/web/story/page/talk`,
      params,
      method: "GET",
    });
  },
  // 获取小镇访
  getInterview(params) {
    return axios.request({
      url: `${prefix}/web/story/talk`,
      params,
      method: "GET",
    });
  },
  // 获取小镇产业
  getIndustryList(params) {
    return axios.request({
      url: `${prefix}/web/article/page/industry`,
      params,
      method: "GET",
    });
  },
  // 获取小镇vr
  getVrList(params) {
    return axios.request({
      url: `${prefix}/web/vr/vr`,
      params,
      method: "GET",
    });
  },
  // 获取小镇详情
  getTown(params) {
    return axios.request({
      url: `${prefix}/web/town`,
      params,
      method: "GET",
    });
  },
  // 获取小镇故事
  getStory(params) {
    return axios.request({
      url: `${prefix}/web/story/story`,
      params,
      method: "GET",
    });
  },
  // 获取图文详情
  getDetail(params) {
    return axios.request({
      url: `${prefix}/web/article`,
      params,
      method: "GET",
    });
  },
  // 申请入驻
  apply(params) {
    return axios.request({
      url: `${prefix}/web/residenceApplication`,
      data: params,
      method: "POST",
    });
  },

  //---------------新版本接口
  // 获取首页dashboard
  getHomeDashboard(params) {
    return axios.request({
      url: `${prefix}/web/homePageData`,
      params,
      method: "GET",
    });
  },
  //搜索小镇
  searchTowns(params) {
    return axios.request({
      url: `${prefix}/web/town/query`,
      params,
      method: "GET",
    });
  },
  // 城市列表
  cityList(params) {
    return axios.request({
      url: `${managerApi}/api/wiki/common/city`,
      params,
      method: "GET",
    });
  },
  // 区域列表
  countyList(params) {
    return axios.request({
      url: `${managerApi}/api/wiki/common/area`,
      params,
      method: "GET",
    });
  },
  //精选小镇
  bestTowns(params) {
    return axios.request({
      url: `${prefix}/web/town/page`,
      params,
      method: "GET",
    });
  },
  //小镇客流数据
  passengerData(params) {
    return axios.request({
      url: `${prefix}/web/homePageData/passenger-flows`,
      params,
      method: "GET",
    });
  },
  //覆盖人数数据
  coverData(params) {
    return axios.request({
      url: `${prefix}/web/homePageData/coverage-peoples`,
      params,
      method: "GET",
    });
  },
  //镇群经济数据
  townEconomic(params) {
    return axios.request({
      url: `${prefix}/web/homePageData/town-Group-Economics`,
      params,
      method: "GET",
    });
  },
  //年度农经收益
  progressEconomic(params) {
    return axios.request({
      url: `${prefix}/web/homePageData/year-economics`,
      params,
      method: "GET",
    });
  },
  // 小镇产业分类
  industryType(params) {
    return axios.request({
      url: `${prefix}/web/homePageData/town-product-types`,
      params,
      method: "GET",
    });
  },
  //小镇活动
  activityList(params) {
    return axios.request({
      url: `${prefix}/town/activity/hot-page`,
      params,
      method: "GET",
    });
  },
  //往期活动
  pastActivityList(params) {
    return axios.request({
      url: `${prefix}/town/activity/past-page`,
      params,
      method: "GET",
    });
  },
  //活动详情
  activityDetail(params) {
    return axios.request({
      url: `${prefix}/town/activity`,
      params,
      method: "GET",
    });
  },
  //阅读量+1
  activityRead(params) {
    return axios.request({
      url: `${prefix}/town/activity/num-incr`,
      params,
      method: "PUT",
    });
  },
  //报名活动-报名人数
  activityApplyInfo(params) {
    return axios.request({
      url: `${prefix}/town/activity/apply-page`,
      params,
      method: "GET",
    });
  },
  //征集活动分页
  activityCollection(params) {
    return axios.request({
      url: `${prefix}/town/activity/product-collect`,
      params,
      method: "GET",
    });
  },
  //vr列表
  vrlist(params) {
    return axios.request({
      url: `${prefix}/web/vr/page`,
      params,
      method: "GET",
    });
  },
  //相册首页
  ablumlist(params) {
    return axios.request({
      url: `${prefix}/web/town/tabs/page`,
      params,
      method: "GET",
    });
  },

  //小镇相册
  townAblum(params) {
    return axios.request({
      url: `${prefix}/web/town/picture/list`,
      params,
      method: "GET",
    });
  },
  //小镇相册图片阅读
  townAblumRead(params) {
    return axios.request({
      url: `${prefix}/web/town/picture/views-incr`,
      params,
      method: "PUT",
    });
  },
  //热门动态3片
  hotspot(params) {
    return axios.request({
      url: `${prefix}/web/article/hotspot`,
      params,
      method: "GET",
    });
  },
  //小镇vr
  townVr(params) {
    return axios.request({
      url: `${prefix}/web/vr/page`,
      params,
      method: "GET",
    });
  },
  //小镇故事、访谈录
  townStory(params) {
    return axios.request({
      url: `${prefix}/web/story/story/page`,
      params,
      method: "GET",
    });
  },
  //小镇产业、小镇动态
  townProduct(params) {
    return axios.request({
      url: `${prefix}/web/article/trend/page`,
      params,
      method: "GET",
    });
  },
  //小镇访谈录、故事详情
  storyDetail(params) {
    return axios.request({
      url: `${prefix}/web/story`,
      params,
      method: "GET",
    });
  },
  //小镇访谈录、故事详情 阅读
  readStoryDetail(params) {
    return axios.request({
      url: `${prefix}/web/story/read-incr`,
      params,
      method: "PUT",
    });
  },
  //文件上传
  uploadFile(data, onUploadProgress) {
    return axiosNew.request({
      url: `${prefix}/common/upload`,
      data,
      method: "POST",
      onUploadProgress: onUploadProgress,
    });
  },
  //第三届”天府旅游名品”市(州) 申报 活动报名
  tianfuSignUp(data) {
    return axiosNew.request({
      url: `${prefix}/competition`,
      data,
      method: "POST",
    });
  },
};
